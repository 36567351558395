import React, { ReactElement, useEffect, useState } from 'react';
import { Form, Header, Message, Segment } from 'semantic-ui-react';
import { CreateUserDto } from '../../../dto/CreateUserDto';
import './create-patient.css';
import { formatISO } from 'date-fns';
import { createPatient, existsByPhone } from '../../../api/routes/users';
import sexOptions from '../../../utils/sex';
import FirstNameInput from '../../../components/pateints/patient/form/first-name-input';
import SecondNameInput from '../../../components/pateints/patient/form/second-name-input';
import PatronymicInput from '../../../components/pateints/patient/form/patronymic-input';
import PhoneNumberInput from '../../../components/pateints/patient/form/phone-number-input';
import EmailInput from '../../../components/pateints/patient/form/email-input';
import BirthdayDatepicker from '../../../components/pateints/patient/form/birthday-datepicker';
import SexDropdown from '../../../components/pateints/patient/form/sex-dropdown';
import { emailRexEx, phoneRexEx } from '../../../utils/re';

export function CreatePatient(): ReactElement {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [patronymic, setPatronymic] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isActiveSaveButton, setActiveSaveButton] = useState(false);
  const [sex, setSex] = useState(sexOptions[0]);
  const [date, setDate] = useState<Date | Date[] | null | undefined>(null);
  const [error, setError] = useState('');

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setActiveSaveButton(
      firstName.trim() !== '' &&
        lastName.trim() !== '' &&
        patronymic.trim() !== '' &&
        phoneNumber !== '' &&
        phoneRexEx.test(String(phoneNumber.toLowerCase())) &&
        email !== '' &&
        emailRexEx.test(String(email.toLowerCase())) &&
        password !== '' &&
        date !== null &&
        date !== undefined
    );
  }, [firstName, lastName, patronymic, phoneNumber, email, password, date]);

  const onCreateUserClickHandler = async () => {
    setLoading(true);
    const isExists = await existsByPhone(phoneNumber);
    if (!isExists) {
      const newUser: CreateUserDto = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: password,
        patronymic: patronymic,
        phoneNumber: phoneNumber,
        sex: sex.value,
        date: formatISO(date as Date, { representation: 'date' })
      };
      console.table(newUser);
      await createPatient(newUser);
      setLoading(false);
      window.location.href = '/patients';
    } else {
      setError('Patient with this phone number already exists');
      setLoading(false);
    }
  };

  return (
    <>
      <Header size={'large'}>Creating patient</Header>
      <Form size={'large'} loading={isLoading}>
        <Segment>
          {error && (
            <Message negative>
              <Message.Header>Error creating patient</Message.Header>
              <p>{error}</p>
            </Message>
          )}
          <FirstNameInput firstName={firstName} onChangeFirstName={setFirstName} />
          <SecondNameInput secondName={lastName} onChangeSecondName={setLastName} />
          <PatronymicInput patronymic={patronymic} onChangePatronymic={setPatronymic} />
          <PhoneNumberInput phoneNumber={phoneNumber} onChangePhoneNumber={setPhoneNumber} disabled={false}/>
          <EmailInput email={email} onChangeEmail={setEmail} />
          <Form.Input
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            required
            placeholder={'Password'}
            label={'Password'}
            type={'password'}
          />
          <SexDropdown sex={sex} onChangeSex={setSex} />
          <BirthdayDatepicker date={date} onChangeDate={setDate} />
          <Form.Button disabled={!isActiveSaveButton} primary={true} onClick={onCreateUserClickHandler}>
            Register
          </Form.Button>
        </Segment>
      </Form>
    </>
  );
}
