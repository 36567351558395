import { Sex } from '../enums/sex';

export interface SexOption {
  key: number;
  text: string;
  value: number;
}

export function getSexByName(value: string): SexOption {
  if (value === 'MALE') {
    return sexOptions[0];
  } else {
    return sexOptions[1];
  }
}

const sexOptions: SexOption[] = [
  {
    key: Sex.MALE,
    text: 'Male',
    value: Sex.MALE
  },
  {
    key: Sex.FEMALE,
    text: 'Female',
    value: Sex.FEMALE
  }
];

export default sexOptions;
