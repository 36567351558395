import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GameDto } from '../../../../../dto/GameDto';
import { getAllGames } from '../../../../../api/routes/games';
import { DiseaseDto } from '../../../../../dto/DiseaseDto';
import { getDiseasesById, updateDiseasesById } from '../../../../../api/routes/diseases';
import { Form, Header, Segment } from 'semantic-ui-react';
import GamesDropDown from '../../../../../components/diseases/form/games-drop-down';
import { DiagnosesDto } from '../../../../../dto/DiagnosesDto';
import { getAllDiagnoses } from '../../../../../api/routes/diagnoses';
import DiagnosesDropDown from '../../../../../components/diseases/form/diagnoses-drop-down';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';
import { formatISO, parseISO } from 'date-fns';
import { dropDownHandOptions } from '../../../../../utils/affected-hand';
import AffectedHandDropDown from '../../../../../components/diseases/form/affected-hand-drop-down';
import { NewDiseasesDto } from '../../../../../dto/NewDiseasesDto';
import { DateType } from '../../../../../utils/date';

interface ParamsType {
  id: string;
}

const PatientEditDiseases: FC = () => {
  const [allGames, setAllGames] = useState<GameDto[]>([]);
  const [diseases, setDiseases] = useState<DiseaseDto | null>();
  const [selectedGamesIds, setSelectedGamesIds] = useState<number[]>([]);
  const [selectedDiagnoses, setSelectedDiagnoses] = useState<number | undefined>();
  const [allDiagnoses, setAllDiagnoses] = useState<DiagnosesDto[]>([]);
  const [startDate, setStartDate] = useState<DateType>();
  const [endDate, setEndDate] = useState<DateType>();
  const [isActiveButton, setActiveButton] = useState<boolean>(false);
  const [affectedHand, setAffectedHand] = useState(dropDownHandOptions[0]);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams<ParamsType>();
  const history = useHistory();

  const fetchAndSetAllGames = async () => {
    const fetched = await getAllGames();
    setAllGames(fetched);
  };

  const fetchAndSetAllDiagnoses = async () => {
    const fetched = await getAllDiagnoses();
    setAllDiagnoses(fetched);
  };

  const handleOnSaveButtonClick = async (): Promise<void> => {
    setLoading(true);
    const dto: NewDiseasesDto = {
      affectedHand: affectedHand.value as string,
      diagnosesId: selectedDiagnoses!,
      gamesIds: selectedGamesIds,
      patientId: '',
      startDate: formatISO(startDate as Date, { representation: 'date' }),
      endDate: formatISO(endDate as Date, { representation: 'date' })
    };
    await updateDiseasesById(diseases!.id.toString(), dto);
    setLoading(false);
    if (diseases) {
      history.push(`/patients/detailed/${diseases.patientId}`);
    }
  };

  const fetchAndSetDiseases = async () => {
    const fetched = await getDiseasesById(id);
    setDiseases(fetched);
    setSelectedGamesIds(fetched.actualGames.map((i) => i.id));
    setSelectedDiagnoses(fetched.diagnosesDto.id);
    setStartDate(parseISO(fetched.startDate));
    setEndDate(parseISO(fetched.endDate));
    setAffectedHand(dropDownHandOptions.filter((value) => value.key === fetched.affectedHand)[0]);
  };

  function onChangeStartDate(event: React.SyntheticEvent<Element, Event> | undefined, data: SemanticDatepickerProps) {
    setStartDate(data.value);
  }

  function onChangeEndDate(event: React.SyntheticEvent<Element, Event> | undefined, data: SemanticDatepickerProps) {
    setEndDate(data.value);
  }

  useEffect(() => {
    fetchAndSetDiseases();
    fetchAndSetAllGames();
    fetchAndSetAllDiagnoses();
  }, []);

  useEffect(() => {
    setActiveButton(
      startDate !== undefined && startDate !== null && endDate !== undefined && endDate !== null && startDate < endDate
    );
  }, [startDate, endDate]);

  return (
    <>
      {diseases && <Header>{`${diseases.diagnosesDto.name} (${diseases.startDate} - ${diseases.endDate})`}</Header>}
      <Form size={'large'}>
        <Segment>
          <GamesDropDown allGames={allGames} selectedGamesIds={selectedGamesIds} onChangeGames={setSelectedGamesIds} />
          <DiagnosesDropDown
            diagnoses={allDiagnoses}
            selectedDiagnosesId={selectedDiagnoses}
            onSelectDiagnoses={setSelectedDiagnoses}
          />
          <AffectedHandDropDown affectedHandValue={affectedHand} onChangeAffectedHand={setAffectedHand} />
          <SemanticDatepicker
            label={'Start date'}
            required
            locale={'ru-RU'}
            type={'basic'}
            value={startDate}
            onChange={onChangeStartDate}
          />
          <SemanticDatepicker
            label={'End date'}
            required
            locale={'ru-RU'}
            type={'basic'}
            value={endDate}
            onChange={onChangeEndDate}
          />
          <Form.Button primary onClick={handleOnSaveButtonClick} disabled={!isActiveButton} loading={isLoading}>
            Save
          </Form.Button>
        </Segment>
      </Form>
    </>
  );
};

export default PatientEditDiseases;
