import React, { ReactElement } from 'react';
import { DiseaseDto } from '../../../../dto/DiseaseDto';
import { Accordion, Button, Grid, GridColumn } from 'semantic-ui-react';
import styles from './patient-diseases.module.css';
import { GameWithResultsDto } from '../../../../dto/GameWithResultsDto';
import ResultsTable from '../../../../components/pateints/games/results/results-table';
import { Link } from 'react-router-dom';
import { affectedHandName } from '../../../../enums/affected-hand';

export interface PatientDiseasesProps {
  patientId: string;
  diseases: DiseaseDto[];
  handleOnResultDelete: (id: number) => void;
  handleOnDiseasesDelete: (id: number) => void;
}

export default function PatientDiseases({
  patientId,
  diseases,
  handleOnResultDelete,
  handleOnDiseasesDelete
}: PatientDiseasesProps): ReactElement {
  return (
    <>
      <Accordion
        className={styles.patientDiseasesAccordion}
        panels={createPanelsPatientDiseases(patientId, diseases, handleOnResultDelete, handleOnDiseasesDelete)}
        styled
      />
    </>
  );
}

function createPanelsPatientDiseases(
  patientId: string,
  diseaseDtos: DiseaseDto[],
  onResultDeleteClick: (id: number) => void,
  handleOnDiseasesDelete: (id: number) => void
) {
  return diseaseDtos.map((value) => ({
    key: value.id,
    title: `${value.diagnosesDto.name} (${value.startDate} - ${value.endDate})`,
    content: {
      content: (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column width={'11'}>
                <Grid>
                  <DiseasesField label={'Hand'} value={affectedHandName(value.affectedHand)} />
                  <DiseasesField label={'Reason'} value={value.diagnosesDto.name} />
                  <DiseasesField label={'Start date'} value={value.startDate} />
                  <DiseasesField label={'End date'} value={value.endDate} />
                  <DiseasesField label={'is active'} value={value.isActive ? 'Yes' : 'No'} />
                  <DiseasesField label={'active games'} value={value.actualGames.map((i) => i.name).join('; ')} />
                </Grid>
              </Grid.Column>
              <GridColumn width={'3'}>
                <Button primary as={Link} to={`/patients/detailed/${value.id}/diseases/edit`}>
                  Edit
                </Button>
              </GridColumn>
              <GridColumn width={'2'}>
                <Button color={'red'} onClick={() => handleOnDiseasesDelete(value.id)}>
                  Remove
                </Button>
              </GridColumn>
            </Grid.Row>
          </Grid>
          <h3>Results:</h3>
          <Accordion.Accordion
            panels={createPanelsPatientGames(patientId, value.id.toString(), value.games, onResultDeleteClick)}
          />
        </>
      )
    }
  }));
}

type DiseasesFieldProps = { label: string; value: string };
const DiseasesField = ({ label, value }: DiseasesFieldProps): ReactElement => {
  return (
    <Grid.Row className={styles.patientDiseasesRow}>
      <Grid.Column width={'4'}>
        <h4>{label}:</h4>
      </Grid.Column>
      <Grid.Column width={'12'}>
        <p>{value}</p>
      </Grid.Column>
    </Grid.Row>
  );
};

function createPanelsPatientGames(
  patientId: string,
  diseasesId: string,
  gameDtos: GameWithResultsDto[],
  onResultDeleteClick: (id: number) => void
) {
  return gameDtos.map((value) => ({
    key: value.game.id,
    title: value.game.name,
    content: {
      content: (
        <div>
          <Button as={Link} to={`/patients/detailed/results/${patientId}/${diseasesId}/${value.game.id}`}>
            More
          </Button>
          <ResultsTable results={value.results.slice(0, 5)} onDeleteClick={onResultDeleteClick} />
        </div>
      )
    }
  }));
}
