import React from 'react';
import { Form } from 'semantic-ui-react';

interface FirstNameInputProps {
  firstName: string;
  onChangeFirstName: (value: string) => void;
}

const FirstNameInput = ({ firstName, onChangeFirstName }: FirstNameInputProps): JSX.Element => {
  return (
    <Form.Input
      value={firstName}
      fluid
      required
      onChange={(event) => {
        onChangeFirstName(event.target.value);
      }}
      placeholder={'First name'}
      label={'First name'}
    />
  );
};

export default FirstNameInput;
