import React, { ReactElement, useState } from 'react';
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react';
import { UserDto } from '../../dto/UserDto';
import { useCookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import { login } from '../../api/routes/users';

export function Login(): ReactElement {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState<UserDto | null>(null);
  const [cookies, setCookie] = useCookies(['token']);
  const [error, setError] = useState<string | null>(null);

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const authorize = async () => {
    try {
      const token = btoa(`${phone}:${password}`);
      const user = await login(token);
      if (user.roles.map((value) => value.name).includes('DOCTOR_ROLE')) {
        setUser(user);
        setCookie('token', token, { path: '/', sameSite: 'lax', maxAge: 86400 * 365 });
      } else {
        setError('You are not a doctor');
      }
    } catch (e) {
      console.error(e)
      setError('Check login and password');
    }
  };

  return (
    <>
      {cookies['token'] && <Redirect to={'/patients'} />}
      <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="blue" textAlign="center">
            Devirta accuracy
          </Header>
          {error && (
            <Message negative>
              <Message.Header>Authorization error</Message.Header>
              <p>{error}</p>
            </Message>
          )}
          <Form size="large">
            <Segment stacked>
              <Form.Input
                value={phone}
                onChange={handlePhoneChange}
                fluid
                icon="phone"
                iconPosition="left"
                placeholder="Phone number"
              />
              <Form.Input
                value={password}
                onChange={handlePasswordChange}
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
              />

              <Button primary fluid size="large" onClick={authorize}>
                Login
              </Button>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </>
  );
}
