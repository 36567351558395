import React from 'react';
import { ResultDto } from '../../../../dto/ResultDto';
import { Button, Table } from 'semantic-ui-react';
import { format, parseISO } from 'date-fns';

export interface ResultItemComponentProps {
  resultDto: ResultDto;
  onDeleteClick: (id: number) => void;
}

export default function ResultItemComponent({ resultDto, onDeleteClick }: ResultItemComponentProps) {
  return (
    <Table.Row>
      <Table.Cell>{format(parseISO(resultDto.time), 'dd/MM/yyyy HH:mm')}</Table.Cell>
      <Table.Cell>{resultDto.isCanceled ? 'Canceled' : 'Completed'}</Table.Cell>
      <Table.Cell>{resultDto.duration}</Table.Cell>
      <Table.Cell>{`${resultDto.value} ${resultDto.game.resultUserName}`}</Table.Cell>
      <Table.Cell>
        <Button
          content="Remove"
          icon="trash"
          labelPosition="right"
          floated={'right'}
          onClick={() => onDeleteClick(resultDto.id)}
        />
      </Table.Cell>
    </Table.Row>
  );
}
