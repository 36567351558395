import React, { ReactElement } from 'react';
import { UserDto } from '../dto/UserDto';
import { Button, Container, Menu, Segment } from 'semantic-ui-react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  currentUser: UserDto | null;
}

export function DHeader({ currentUser, ...other }: HeaderProps): ReactElement {
  const [, , removeCookie] = useCookies(['token']);

  const handleLogoutButtonClick = () => {
    removeCookie('token');
  };

  return (
    <div {...other}>
      <Segment textAlign={'center'} style={{ padding: '0px' }}>
        <Menu size={'large'} text={true}>
          <Container>
            <Menu.Item header={true} style={{ fontSize: 1.2 + 'em' }}>
              <Link to={'/'}>Devirta. Accuracy</Link>
            </Menu.Item>
            <Menu.Item position={'right'} style={{ fontSize: 1.2 + 'em' }}>
              {currentUser && (
                <Menu.Item>
                  {currentUser.lastName} {currentUser.firstName}
                </Menu.Item>
              )}
              <Menu.Item>
                <Button as={'a'} basic={true} color={'black'} onClick={handleLogoutButtonClick}>
                  Log out
                </Button>
              </Menu.Item>
            </Menu.Item>
          </Container>
        </Menu>
      </Segment>
    </div>
  );
}
