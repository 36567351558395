import React, { ReactElement, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { PatientDto } from '../../../dto/PatientDto';
import { Button, Grid, Segment } from 'semantic-ui-react';
import styles from './detailed-patient.module.css';
import DetailedPatientInfoField from './detailed-patient-info-field';
import PatientDiseases from './diseases/patient-diseases';
import { deletePatientFromDoctor, getPatientById } from '../../../api/routes/users';
import { deleteResult } from '../../../api/routes/results';
import { deleteDiseases } from '../../../api/routes/diseases';
import ConfirmDialog from '../../../components/confirm/confirm-dialog';
import { getSexByName } from '../../../utils/sex';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';

interface ParamsTypes {
  id: string;
}

export function DetailedPatient(): ReactElement {
  const { id } = useParams<ParamsTypes>();
  const [patient, setPatient] = useState<PatientDto | null>(null);
  const history = useHistory();
  const [isOpenDeletePatientDialog, setOpenDeletePatientDialog] = useState(false);
  const [deleteModal, setDeleteModal] = useState<ReactElement | null>(null);

  const fetchPatientAsync = async () => {
    const fetched = await getPatientById(id);
    setPatient(fetched);
  };

  const handleOnResultDelete = async (id: number) => {
    await deleteResult(id);
    await fetchPatientAsync();
  };

  const handleOnDiseasesDelete = async (id: number) => {
    await deleteDiseases(id.toString());
    await fetchPatientAsync();
  };

  const handleOnDeletePatient = async (patientId: number) => {
    await deletePatientFromDoctor(patientId);
    history.replace('/patients');
  };

  useEffect(() => {
    fetchPatientAsync();
  }, []);

  return (
    <>
      {patient && (
        <>
          <Grid>
            <Grid.Row centered>
              <Grid.Column width={11}>
                <h1>Patient card</h1>
              </Grid.Column>
              <Grid.Column width={3}>
                <Button
                  as={Link}
                  to={`/patients/detailed/${patient.userDto.id}/edit`}
                  className={styles.patientInfoMenuButton}
                >
                  Edit
                </Button>
              </Grid.Column>
              <Grid.Column width={2}>
                <Button
                  className={styles.patientInfoMenuButton}
                  primary
                  onClick={() => {
                    setOpenDeletePatientDialog(true);
                  }}
                >
                  Remove
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Segment>
            <Grid className={styles.detailedPatientInfoGrid}>
              <DetailedPatientInfoField fieldName={'Last name'} value={patient.userDto.lastName} />
              <DetailedPatientInfoField fieldName={'First name'} value={patient.userDto.firstName} />
              <DetailedPatientInfoField fieldName={'Patronymic'} value={patient.userDto.patronymic} />
              <DetailedPatientInfoField fieldName={'Phone number'} value={patient.userDto.phone} />
              <DetailedPatientInfoField fieldName={'Mail'} value={patient.userDto.email} />
              <DetailedPatientInfoField fieldName={'Gender'} value={getSexByName(patient.userDto.sex).text} />
              <DetailedPatientInfoField
                fieldName={'Birthday'}
                value={format(parseISO(patient.userDto.birthday), 'd MMMM yyyy', { locale: ru})}
              />
              <Grid.Row>
                <Grid.Column>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={14}>
                        <h2>Diseases:</h2>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Button primary as={Link} to={`/patients/detailed/${id}/add`}>
                          Add
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <PatientDiseases
                    patientId={patient.userDto.id.toString()}
                    diseases={patient.diseases}
                    handleOnDiseasesDelete={(diseasesId) => {
                      setDeleteModal(
                        <ConfirmDialog
                          title={'Confirm action'}
                          message={`Do you really want to remove the disease?`}
                          isOpen={true}
                          setOpen={() => setDeleteModal(null)}
                          onConfirm={() => {
                            handleOnDiseasesDelete(diseasesId);
                            setDeleteModal(null);
                          }}
                        />
                      );
                    }}
                    handleOnResultDelete={(resultId) => {
                      setDeleteModal(
                        <ConfirmDialog
                          title={'Confirm action'}
                          message={`Are you sure you want to delete the result ?`}
                          isOpen={true}
                          setOpen={() => setDeleteModal(null)}
                          onConfirm={() => {
                            handleOnResultDelete(resultId);
                            setDeleteModal(null);
                          }}
                        />
                      );
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <ConfirmDialog
            title={'Confirm action'}
            /* eslint-disable-next-line max-len */
            message={`Are you sure you want to delete the patient ${patient.userDto.lastName} ${patient.userDto.firstName} ${patient.userDto.patronymic} ?`}
            isOpen={isOpenDeletePatientDialog}
            setOpen={setOpenDeletePatientDialog}
            onConfirm={() => {
              handleOnDeletePatient(patient.userDto.id);
            }}
          />
          {deleteModal}
        </>
      )}
    </>
  );
}
