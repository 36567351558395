import React from 'react';
import { Form } from 'semantic-ui-react';

interface PatronymicInputProps {
  patronymic: string;
  onChangePatronymic: (value: string) => void;
}

const PatronymicInput = ({ patronymic, onChangePatronymic }: PatronymicInputProps): JSX.Element => {
  return (
    <Form.Input
      value={patronymic}
      onChange={(event) => {
        onChangePatronymic(event.target.value);
      }}
      required
      placeholder={'Patronymic'}
      label={'Patronymic'}
    />
  );
};

export default PatronymicInput;
