import React from 'react';
import { GameDto } from '../../../dto/GameDto';
import { gamesToDropDownItemProps } from '../../../utils/games';
import { Form } from 'semantic-ui-react';

interface GamesDropDownProps {
  allGames: GameDto[];
  selectedGamesIds: number[];
  onChangeGames: (gamesIds: number[]) => void;
}

const GamesDropDown = ({ allGames, selectedGamesIds, onChangeGames }: GamesDropDownProps): JSX.Element => {
  return (
    <Form.Dropdown
      label={'Choose game'}
      placeholder={'Choose game'}
      fluid
      multiple
      selection
      options={gamesToDropDownItemProps(allGames)}
      value={selectedGamesIds}
      onChange={(e, { value }) => onChangeGames(value as number[])}
    />
  );
};

export default GamesDropDown;
