import React from 'react';
import { DateType } from '../../../../utils/date';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';

interface BirthdayDatepickerProps {
  date: DateType;
  onChangeDate: (date: DateType) => void;
}

const BirthdayDatepicker = ({ date, onChangeDate }: BirthdayDatepickerProps): JSX.Element => {
  function onChangeDateEventHandler(
    event: React.SyntheticEvent<Element, Event> | undefined,
    data: SemanticDatepickerProps
  ) {
    onChangeDate(data.value);
  }

  return (
    <SemanticDatepicker
      label={'Birthday'}
      required
      locale={'ru-RU'}
      value={date}
      type={'basic'}
      onChange={onChangeDateEventHandler}
    />
  );
};

export default BirthdayDatepicker;
