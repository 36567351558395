import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import { emailRexEx } from '../../../../utils/re';

interface EmailInputProps {
  email: string;
  onChangeEmail: (value: string) => void;
}

const EmailInput = ({ email, onChangeEmail }: EmailInputProps): JSX.Element => {
  const [error, setError] = useState(false);

  return (
    <Form.Input
      value={email}
      onChange={(event) => {
        setError(!emailRexEx.test(String(event.target.value.toLowerCase())))
        onChangeEmail(event.target.value);
      }}
      required
      placeholder={'Mail'}
      label={'Mail'}
      error={error ? 'Wrong mail format' : null}
    />
  );
};

export default EmailInput;
