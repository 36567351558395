import React from 'react';
import sexOptions, { SexOption } from '../../../../utils/sex';
import { Form } from 'semantic-ui-react';

interface SexDropdownProps {
  sex: SexOption;
  onChangeSex: (value: SexOption) => void;
}

const SexDropdown = ({ sex, onChangeSex }: SexDropdownProps): JSX.Element => {
  return (
    <Form.Dropdown
      label={'Gender'}
      options={sexOptions}
      selection
      value={sex.value}
      onChange={(e, { value }) => onChangeSex(sexOptions[value as number])}
    />
  );
};

export default SexDropdown;
