import React from 'react';
import { dropDownHandOptions } from '../../../utils/affected-hand';
import { DropdownItemProps, Form } from 'semantic-ui-react';

interface AffectedHandDropDownProps {
  affectedHandValue: DropdownItemProps;
  onChangeAffectedHand: (value: DropdownItemProps) => void;
}

const AffectedHandDropDown = ({ affectedHandValue, onChangeAffectedHand }: AffectedHandDropDownProps): JSX.Element => {
  return (
    <Form.Dropdown
      label={'Choose affected hand'}
      fluid
      selection
      options={dropDownHandOptions}
      value={affectedHandValue.value}
      onChange={(e, { value }) => onChangeAffectedHand(dropDownHandOptions.filter((it) => it.key === value)[0])}
    />
  );
};

export default AffectedHandDropDown;
