import React from 'react';
import { diagnosesToDropDownItemProps } from '../../../utils/diagnoses';
import { Form } from 'semantic-ui-react';
import { DiagnosesDto } from '../../../dto/DiagnosesDto';

interface DiagnosesDropDownProps {
  diagnoses: DiagnosesDto[];
  selectedDiagnosesId: number | undefined;
  onSelectDiagnoses: (id: number) => void;
}

const DiagnosesDropDown = ({
  diagnoses,
  selectedDiagnosesId,
  onSelectDiagnoses
}: DiagnosesDropDownProps): JSX.Element => {
  return (
    <Form.Dropdown
      placeholder={'Choose a diagnosis'}
      label={'Choose a diagnosis'}
      fluid
      selection
      options={diagnosesToDropDownItemProps(diagnoses)}
      value={selectedDiagnosesId}
      onChange={(e, { value }) => onSelectDiagnoses(value as number)}
    />
  );
};

export default DiagnosesDropDown;
