import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UserDto } from '../../../../dto/UserDto';
import { getUserById, updatePatient } from '../../../../api/routes/users';
import { Form, Header, Segment } from 'semantic-ui-react';
import sexOptions, { getSexByName } from '../../../../utils/sex';
import FirstNameInput from '../../../../components/pateints/patient/form/first-name-input';
import SecondNameInput from '../../../../components/pateints/patient/form/second-name-input';
import PatronymicInput from '../../../../components/pateints/patient/form/patronymic-input';
import PhoneNumberInput from '../../../../components/pateints/patient/form/phone-number-input';
import EmailInput from '../../../../components/pateints/patient/form/email-input';
import SexDropdown from '../../../../components/pateints/patient/form/sex-dropdown';
import BirthdayDatepicker from '../../../../components/pateints/patient/form/birthday-datepicker';
import { DateType } from '../../../../utils/date';
import { formatISO, parseISO } from 'date-fns';
import { CreateUserDto } from '../../../../dto/CreateUserDto';
import { emailRexEx } from '../../../../utils/re';

interface EditPatientURLParams {
  id: string;
}

const EditPatient: FC = () => {
  const { id } = useParams<EditPatientURLParams>();
  const [user, setUser] = useState<UserDto | null>(null);
  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [patronymic, setPatronymic] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [sex, setSex] = useState(sexOptions[0]);
  const [date, setDate] = useState<DateType>(null);
  const [isActiveSaveButton, setActiveSaveButton] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const fetchAndSetUser = async () => {
    const fetched = await getUserById(id);
    setUser(fetched);
    setFirstName(fetched.firstName);
    setSecondName(fetched.lastName);
    setPatronymic(fetched.patronymic);
    setPhoneNumber(fetched.phone);
    setEmail(fetched.email);
    setSex(getSexByName(fetched.sex));
    setDate(parseISO(fetched.birthday));
  };

  useEffect(() => {
    setActiveSaveButton(
      firstName.trim() !== '' &&
        secondName.trim() !== '' &&
        patronymic.trim() !== '' &&
        phoneNumber !== '' &&
        email !== '' &&
        emailRexEx.test(String(email.toLowerCase())) &&
        date !== null &&
        date !== undefined
    );
  }, [firstName, secondName, patronymic, phoneNumber, email, date]);

  const onSaveClickHandler = async () => {
    setLoading(true);
    console.log('');
    const dto: CreateUserDto = {
      date: formatISO(date as Date, { representation: 'date' }),
      email: email,
      firstName: firstName,
      lastName: secondName,
      password: '',
      patronymic: patronymic,
      phoneNumber: phoneNumber,
      sex: sex.value
    };
    if (user) {
      await updatePatient(user!.id, dto);
      setLoading(false);
      history.push(`/patients/detailed/${user.id}`);
    }
  };

  useEffect(() => {
    fetchAndSetUser();
  }, []);

  return (
    <>
      {user && (
        <>
          <Header>Patient editing</Header>
          <Form size={'large'}>
            <Segment>
              <FirstNameInput firstName={firstName} onChangeFirstName={setFirstName} />
              <SecondNameInput secondName={secondName} onChangeSecondName={setSecondName} />
              <PatronymicInput patronymic={patronymic} onChangePatronymic={setPatronymic} />
              <PhoneNumberInput phoneNumber={phoneNumber} onChangePhoneNumber={setPhoneNumber} disabled={true}/>
              <EmailInput email={email} onChangeEmail={setEmail} />
              <SexDropdown sex={sex} onChangeSex={setSex} />
              <BirthdayDatepicker date={date} onChangeDate={setDate} />
              <Form.Button
                loading={isLoading}
                disabled={!isActiveSaveButton}
                primary={true}
                onClick={onSaveClickHandler}
              >
                Save
              </Form.Button>
            </Segment>
          </Form>
        </>
      )}
    </>
  );
};

export default EditPatient;
