import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { Grid, Header, Input, List, Segment } from 'semantic-ui-react';
import { UserDto } from '../../../dto/UserDto';
import AvailablePatientListItem from './available-patient-list-item';
import './patients-available.css';
import { addPatientToDoctor, getAvailableUsers } from '../../../api/routes/users';
import { filterUsersBySearchValue } from '../../../utils/users';

export default function PatientsAvailable(): ReactElement {
  const [users, setUsers] = useState<UserDto[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredUsers, setFilteredUsers] = useState<UserDto[]>(users);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const onSearchChangeHandler = (event: ChangeEvent, data: object) => {
    // @ts-ignore
    setSearchValue(data.value);
  };

  useEffect(() => {
    setFilteredUsers(filterUsersBySearchValue(users, searchValue));
  }, [users, searchValue]);

  const handleOnAddClick = async (patientId: number) => {
    await addPatientToDoctor(patientId);
    await fetchAvailableUsersAsync();
  };

  const fetchAvailableUsersAsync = async () => {
    const fetched = await getAvailableUsers();
    setUsers(fetched);
  };

  useEffect(() => {
    fetchAvailableUsersAsync();
  }, []);

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <Header size={'large'}>Available Patients</Header>
          </Grid.Column>
          <Grid.Column floated={'right'} width={4}>
            <Input
              value={searchValue}
              onChange={onSearchChangeHandler}
              icon={'search'}
              placeholder={'Search...'}
              style={{ width: 100 + '%' }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Segment>
        {users.length === 0 && <p className={'no-data'}>No data</p>}
        <List divided={true} size={'big'}>
          {filteredUsers.map((v) => (
            <AvailablePatientListItem key={v.id} user={v} onAddClick={handleOnAddClick} />
          ))}
        </List>
      </Segment>
    </>
  );
}
