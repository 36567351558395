import { DropdownItemProps } from 'semantic-ui-react';
import { AffectedHand } from '../enums/affected-hand';

const dropDownHandOptions: DropdownItemProps[] = [
  {
    key: AffectedHand.LEFT,
    text: 'Left',
    value: AffectedHand.LEFT
  },
  {
    key: AffectedHand.RIGHT,
    text: 'Right',
    value: AffectedHand.RIGHT
  }
];

export { dropDownHandOptions };
